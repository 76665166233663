<script>
import store from '../store.js'
import event_mixin from "../mixins/event.js"
import common_mixin from "../mixins/common.js"

  export default {
    name: 'vehicle-details',
    data () {
      return {
        shared: store.state,
      }
    },
    mixins: [ event_mixin, common_mixin ],
    computed: {
      vehicle: function() {
        return this.shared.selectedVehicle;
      }
    },
    methods: {
      save() {
        this.loading = true;
        if (this.vehicle.id) {
          this.$http.post(process.env.VUE_APP_API_URL + "vehicle/", this.vehicle)
            .then((result, err) => {
              this.processResponse(result, err, false);
            })
        } else {
          this.$http.put(process.env.VUE_APP_API_URL + "vehicle/", this.vehicle)
            .then((result, err) => {
              this.processResponse(result, err, true);
            })
        }
      },
      processResponse(result, err, isNew) {
        this.loading = false
        if (err) {
          this.error = err.toString()
        } else {
          if (isNew) {
            store.addEvent(result.data);
            this.close();
          }
        }
      },
      getKm() {
        var e = this.shared.events[this.shared.events.length - 1];
        return e ? e.km : '0';
      },
      getSumCost() {
        return this.shared.events.reduce((a, b) => a + (+b['costs'] || 0), 0);
      },
      getSumFueling() {
        return this.shared.events.reduce((a, b) => a + (b['type'] == 1 ? (+b['quantity'] || 0) : 0), 0);
      }
    },
    components: {
    }
  };
</script>
<template>
<div class="ui segment">
          <form class="ui form">
            <div class="field">
            <label></label>
            <div class="three fields">
              <div class="field">
                <input type="text" name="make" placeholder="Gyártmány" readonly="" v-model="vehicle.make">
              </div>
              <div class="field">
                <input type="text" name="type" placeholder="Típus" readonly="" v-model="vehicle.type">
              </div>
              <div class="field">
                <input type="text" name="version" placeholder="Verzió" readonly="" v-model="vehicle.version">
              </div>
            </div>
          </div>
         </form>
         <div class="ui divider"></div>
         <div class="ui statistics" style="justify-content:center;">
              <div class="ui statistic" style="margin-x">
                <div class="value">
                  {{ formatNumber(this.getKm()) }}
                </div>
                <div class="label">
                  Kilométer óra
                </div>
              </div>
              <div class="ui statistic">
                <div class="value">
                  {{ formatNumber(this.getSumCost()) }}
                </div>
                <div class="label">
                  Összes költség
                </div>
              </div>
              <div class="ui statistic">
                <div class="value">
                  {{ this.getSumFueling().toFixed(2) }}
                </div>
                <div class="label">
                  Összes tankolás
                </div>
              </div>
        </div>
        <div class="ui divider"></div>
        <div class="ui cards centered">
          <div class="card">
            <div class="content">
              <div class="header">Olajcsere periódus</div>
              <div class="description">
                {{ formatNumber(vehicle.intervals[0].km) }} km / {{ vehicle.intervals[0].month }} hónap
              </div>
            </div>
            <div class="ui bottom attached button">
              <i class="pencil icon"></i>
              MÓDOSÍT
            </div>
          </div>
          <div class="card">
            <div class="content">
              <div class="header">Következő olajcsere</div>
              <div class="description">
                {{ getNextOilChange(this.shared.selectedVehicle) }}
              </div>
            </div>
            <div class="ui bottom attached button">
              <i class="cart icon"></i>
              RENDELÉS
            </div>
          </div>
          <div class="card">
            <div class="content">
              <div class="header">Műszaki érvényessége</div>
              <div class="description">
                {{ vehicle.technicalInspectionValid }}
              </div>
            </div>
            <div class="ui bottom attached button">
              <i class="add icon"></i>
              EMLÉKEZTETŐ
            </div>
          </div>
          
        </div>

            
</div>
</template>
<style>
  
</style>