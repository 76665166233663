<script>
import store from '../store.js'
import mixin from "../mixins/event.js"
import { ModelListSelect } from 'vue-search-select'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/hu';


  export default {
    name: 'new-vehicle-modal',
    created() {
      this.fetchCategories();
    },
    data () {
      return {
        shared: store.state,
        loading:false,
        error: false,
        vehicle:{
          make: null, model: null, type: null
        },
        selectedCategory: {},
        selectedMake: {},
        selectedModel: {},
        selectedType: {},
        categories:[],
        makes: [],
        models: [],
        types: [],
        advise: {},
        selectedInterval: null
      }
    },
    mixins: [ mixin ],
    computed: {
      event: function() {
        return this.shared.selectedEvent;
      }
    },
    methods: {
      close() {
        this.$emit('close');
      },
      save() {
        this.loading = true;
        this.vehicle.make = this.selectedMake.result;
        this.vehicle.model = this.selectedModel.result;
        this.vehicle.type = this.selectedType.result;
        let data = {
          vehicle: this.vehicle,
          advise: this.advise,
          selectedInterval: this.selectedInterval
        };
        if (this.event.id) {
          this.$http.post(process.env.VUE_APP_API_URL + "vehicle/", data)
            .then((result, err) => {
              this.processResponse(result, err);
            })
        } else {
          this.event.vehicle_id=this.shared.selectedVehicle.id;
          this.$http.put(process.env.VUE_APP_API_URL + "vehicle/", data)
            .then((result, err) => {
              this.processResponse(result, err);
            })
        }
      },
      processResponse(result, err) {
        this.loading = false
        if (err) {
          this.error = err.toString()
        } else {
          this.close();
        }
      },
      fetchCategories() {
        this.categories=[];
        this.makes = [];
        this.models = [];
        this.types = [];
        this.$http.get(process.env.VUE_APP_API_URL + "advise/categories")
            .then((result) => {
              this.categories = result.data;
            })
      },
      fetchMakes() {
        this.makes = [];
        this.models = [];
        this.types = [];
        this.$http.get(process.env.VUE_APP_API_URL + "advise/makes/" + this.selectedCategory.id)
            .then((result) => {
              this.makes = result.data;
            })
      },
      fetchModels() {
        this.models = [];
        this.types = [];
        this.$http.get(process.env.VUE_APP_API_URL + "advise/models/" + this.selectedMake.id)
            .then((result) => {
              this.models = result.data;
            })
      },
      fetchTypes() {
        this.types = [];
        this.$http.get(process.env.VUE_APP_API_URL + "advise/types/" + this.selectedModel.id)
            .then((result) => {
              this.types = result.data;
            })
      },
      getAdvise() {
        this.$http.get(process.env.VUE_APP_API_URL + "advise/recommendation/" + this.selectedType.id)
            .then((result) => {
              this.advise = result.data;
              this.vehicle.oilCapacity = result.data.capacity[0];
            })
      }
    },
    components: {
		ModelListSelect,
		DatePicker
    }
  };
</script>
<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="iu modal" role="dialog"  aria-labelledby="modalTitle"  aria-describedby="modalDescription">
        <header class="modal-header" id="modalTitle">
            <h2 class="ui header" >
              Új jármű
            </h2>
        </header>
        
        <section class="modal-body" id="modalDescription">
			<form class="ui form">
				<div class="field" style="text-align: left;">
					<model-list-select v-if="categories.length > 0" :list="categories" 
						v-model="selectedCategory" option-value="id" option-text="result" 
						@input="fetchMakes" placeholder="Válasszon kategóriát...">
						</model-list-select>
				</div>
        <div class="field" style="text-align: left;">
                <model-list-select v-if="makes.length > 0" :list="makes"
                          v-model="selectedMake"
                          option-value="id"
                          option-text="result"
                          @input="fetchModels"
                          placeholder="Válasszon gyártmányt...">
                </model-list-select>
        </div>
        <div class="field" style="text-align: left;">
                <model-list-select v-if="models.length > 0" :list="models"
                          v-model="selectedModel"
                          option-value="id"
                          option-text="result"
                          @input="fetchTypes"
                          placeholder="Válasszon modellt...">
                </model-list-select>
        </div>
        <div class="field" style="text-align: left;">
                <model-list-select v-if="types.length > 0" :list="types"
                          v-model="selectedType"
                          option-value="id"
                          option-text="result"
                          @input="getAdvise"
                          placeholder="Válasszon típust...">
                </model-list-select>
        </div>
        <div class="field" style="text-align: left;">
					<label>Motorolaj mennyisége [l]:</label>
					<input type="text" name="oilCapacity" placeholder="Motorolaj mennyisége" v-model="vehicle.oilCapacity">
        </div>
				<div class="inline fields">
					<label>Olajcsere periódus:</label>
					<div class="field" v-for="(interval, index) in advise.intervals" v-bind:key="interval.km">
						<div class="ui radio checkbox">
							<input type="radio" :value="index" v-model="selectedInterval">
							<label>{{ interval.km + " km / " + interval.month + " hónap" }}</label>
						</div>
					</div>
				</div>
        <div class="field" style="text-align: left;">
					<label>Rendszám:</label>
					<input type="text" name="plateNumber" placeholder="Rendszám" v-model="vehicle.plateNumber">
                </div>
                <div class="field" style="text-align: left;">
					<label>Gyártási év:</label>
					<input type="text" name="year" placeholder="Gyártási év" v-model="vehicle.year">
                </div>
                <div class="field">
					<label>Km óra állás</label>
					<input type="text" name="km" placeholder="Km óra állás" v-model="vehicle.km">
                </div>
                <div class="field" v-if="hasQuantity(event.type)">
					<label>Műszaki érvényessége</label>
					<date-picker v-model="vehicle.technicalInspectionValid" valueType="format"></date-picker>
                </div>
              </form>
        </section>
        <footer class="modal-footer">
            <button class="ui small primary button" type="button" @click="save">Mentés</button>
            <button
              type="button"
              class="ui small button"
              @click="close"
              aria-label="Close modal">
              Mégsem
            </button>
        </footer>
      </div>
    </div>
  </transition>
</template>
<style>
  label {
    text-align: left;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
.modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width:500px;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    border: none;
    font-size: 20px;
    padding: 20px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
  }

  .mx-datepicker {
    width:100%
  }
</style>