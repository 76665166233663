<template>
  <div class="home" v-if="shared.selectedVehicle.id">
    <h1 class="ui header">{{ shared.selectedVehicle.plateNumber.toUpperCase() }}</h1>
    <div class="ui divider"></div>
    <div class="ui segment">
      <VehicleDetails/>
    </div>
    <div v-if="shared.loading" class="loading">
      Betöltés...
    </div>

    <div v-if="shared.error" class="error">
      {{ error }}
    </div>
    <EventList/>
  </div>
</template>

<script>
// @ is an alias to /src
import EventList from '@/components/EventList.vue'
import VehicleDetails from '@/components/VehicleDetails.vue'
import store from '@/store.js'

export default {
  name: 'Vehicle',
  data() {
    return {
      shared: store.state
    }
  },
  created() {
    this.setSelectedVehicle();
  },
  watch: {
    // call again the method if the route changes
    '$route': 'setSelectedVehicle'
  },
  components: {
    EventList,
    VehicleDetails
  },
  methods: {
    setSelectedVehicle() {
      store.setSelectedVehicle(this.$route.params.id);
    }
  }
}
</script>
