<script>
import store from '../store.js'
import mixin from "../mixins/event.js"
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/hu';

  export default {
    name: 'modal',
    data () {
      return {
        shared: store.state,
        loading:false,
        error: false
      }
    },
    mixins: [ mixin ],
    computed: {
      event: function() {
        return this.shared.selectedEvent;
      }
    },
    methods: {
      close() {
        this.$emit('close');
      },
      save() {
        this.loading = true;
        if (this.event.id) {
          this.$http.post(process.env.VUE_APP_API_URL + "event/", this.event)
            .then((result, err) => {
              this.processResponse(result, err, false);
            })
        } else {
          this.event.vehicle_id=this.shared.selectedVehicle.id;
          this.$http.put(process.env.VUE_APP_API_URL + "event/", this.event)
            .then((result, err) => {
              this.processResponse(result, err, true);
            })
        }
      },
      processResponse(result, err, isNew) {
        this.loading = false
        if (err) {
          this.error = err.toString()
        } else {
          if (isNew) {
            store.addEvent(result.data);
          }
          this.close();
        }
      }
    },
    components: {
      DatePicker
    }
  };
</script>
<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="iu modal" role="dialog"  aria-labelledby="modalTitle"  aria-describedby="modalDescription">
        <header class="modal-header" id="modalTitle">
            <h2 class="ui header" v-if="this.shared.selectedEvent.id">
              <i :class="[getEventTypeIcon(event.type), ' icon']"></i>
              <div class="content">
                {{ getEventTypeName(event.type).toUpperCase() }}
              </div>
            </h2>
            <select class="ui dropdown" style="width:100%;border-radius:.3rem" 
                v-if="!this.shared.selectedEvent.id"
                v-model="event.type">
              <option value="-1" selected>- Válasszon esemény típust -</option>
              <option v-for="n in getEventTypeNum()" v-bind:key="n" :value="n-1">{{ getEventTypeName(n-1).toUpperCase() }}</option>
            </select>
        </header>
        
        <section
          class="modal-body"
          id="modalDescription">
          <form class="ui form">
                <div class="field" style="text-align: left;">
                  <label>Dátum</label>
                  <date-picker v-model="event.date" valueType="format"></date-picker>
                </div>
                <div class="field">
                  <label>Km óra állás</label>
                  <input type="text" name="km" placeholder="Km óra állás" v-model="event.km">
                </div>
                <div class="field" v-if="hasQuantity(event.type)">
                  <label>Mennyiség</label>
                  <input type="text" name="quantity" placeholder="Mennyiség" v-model="event.quantity">
                </div>
                <div class="field">
                  <label>Költség</label>
                  <input type="text" name="costs" placeholder="Költség" v-model="event.costs">
                </div>
                <div class="field">
                  <label>Megjegyzés</label>
                  <textarea rows="2" name="remarks" v-model="event.remarks"></textarea>
                </div>
              </form>
        </section>
        <footer class="modal-footer">
          
            <button class="ui small primary button" type="button" @click="save">Mentés</button>
            <button
              type="button"
              class="ui small button"
              @click="close"
              aria-label="Close modal">
              Mégsem
            </button>
        </footer>
      </div>
    </div>
  </transition>
</template>
<style>
  label {
    text-align: left;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
.modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width:500px;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    border: none;
    font-size: 20px;
    padding: 20px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
  }

  .mx-datepicker {
    width:100%
  }
</style>