<template>
  <div id="app">
    <div class="ui secondary pointing menu" style="margin-bottom:30px">
      <img alt="logo" src="./assets/logo.png">
    
      <router-link to="/">
        <a :class="['item', $route.name === 'Home' ? 'active' : '']" >
          Járművek
        </a>
      </router-link>
      <router-link to="/riport">
        <a :class="['item', $route.name === 'Report' ? 'active' : '']" >
          Jelentések
        </a>
      </router-link>
      <router-link to="/about">
        <a :class="['item', $route.name === 'About' ? 'active' : '']" >
          Segítség
        </a>
      </router-link>
      <div class="right menu">
        <div class="item">
          <div class="ui transparent icon input">
            <input type="text" placeholder="Keresés...">
            <i class="search link icon"></i>
          </div>
        </div>
      </div>
    </div>
    <router-view/>
  </div>
  
</template>
<script>
import store from './store.js'

export default {
  name : 'App',

  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    store.setHttp(this.$http);
  },
  
}
</script>
<style>
@import './assets/semantic.css';
@import './assets/dropdown.min.css';

body {
  background-color: #e5e9f0;
}

.item{
  height:100%;
}


</style>
