<template>
<div class="vehicle-container">
    <div v-if="shared.vehicles" class="content">
      <table class="ui celled table">
        <thead>
          <tr><th>Rendszám</th>
          <th>Márka</th>
          <th>Típus</th>
          <th>Évjárat</th>
          <th>Műszaki érv.</th>
          <th>Köv. olajcsere<i class="question circle outline icon"></i></th>
          <th></th>
        </tr></thead>
        <tbody>
          <tr v-for="vehicle in shared.vehicles" v-bind:key="vehicle.plateNumber">
            <td data-label="Rendszám"><router-link :to="'/vehicle/' + vehicle.id">{{ vehicle.plateNumber.toUpperCase() }}</router-link></td>
            <td data-label="Márka">{{ vehicle.make }}</td>
            <td data-label="Típus">{{ vehicle.type }}</td>
            <td data-label="Évjárat">{{ vehicle.year }}</td>
            <td data-label="Műszaki érv.">{{ vehicle.technicalInspectionValid }}</td>
            <td data-label="Köv. olajcsere" v-html="getNextOilChange(vehicle)"></td>
            <td>
              <router-link :to="'/vehicle/' + vehicle.id" v-slot="{href, navigate}">
                  <button :href="href" @click="navigate" class='ui primary icon button'>
                    <i class="folder open icon"></i>
                  </button>
              </router-link>
              <button class="ui icon button">
                <i class="remove icon"></i>
              </button>
              </td>
          </tr>
        </tbody>
        <tfoot class="full-width">
          <tr>
            <th colspan="7">
              <button class="ui left floated small primary labeled icon button" @click="showModal()">
                <i class="add icon"></i> Hozzáadás
              </button>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
    <newvehiclemodal v-show="isModalVisible" @close="closeModal"/>
  </div>
  
</template>

<script>
import store from '../store.js'
import mixin from '@/mixins/event.js'
import newvehiclemodal from './NewVehicleModal.vue';

export default {
  components: {
    newvehiclemodal
  },
  data () {
    return {
      loading: false,
      error: null,
      isModalVisible: false,
      shared: store.state
    }
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    store.fetchVehicles();
  },
  mixins: [mixin],
  methods: {
    showModal() {
        this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
