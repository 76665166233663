export default {
    methods : {
        getEventTypeNum: function() {
            return 5;
        },
        hasQuantity: function(code) {
            return (code < 2);
        },
        getEventTypeName: function(code) {
            switch (code) {
                case 0 : return 'olajcsere';
                case 1 : return 'tankolás';
                case 2 : return 'szervíz';
                case 3 : return 'műszaki vizsga';
                case 4 : return 'egyéb';
                default: return '-';
            } 
        },
    
        getEventTypeIcon: function(code) {
            switch (code) {
                case 0 : return 'tint';
                case 1 : return 'battery full';
                case 2 : return 'wrench';
                case 3 : return 'clipboard outline';
                case 4 : return 'cog';
                default: return 'ellipsis horizontal';
            } 
        },

        getNextOilChange(vehicle) {
            if (vehicle.intervals.length > 0 && vehicle.events.length > 0) {
              var lastChangeEvent = vehicle.events.find(e => e.type == 0);
              if (lastChangeEvent) {
                var lastChange = new Date(vehicle.events[0].created_at);
                lastChange.setMonth(lastChange.getMonth() + +vehicle.intervals[0].month);
                var nextChange = lastChange.getFullYear() + '-' + ("0"+(lastChange.getMonth() + 1)).slice(-2) + '-' + lastChange.getDate();
                
                var nextKm = +vehicle.events[0].km + +vehicle.intervals[0].km;
                nextKm = new Intl.NumberFormat('hu-HU', { maximumSignificantDigits: 3 }).format(nextKm);
        
                return nextKm + 'km / ' + nextChange;
              }
            }
      
            return '-';
          }
    }
}
