export default {
    
        http: null,

        state : {
            vehicles: [],
            selectedVehicle: {id: null},
            events: [],
            selectedEvent: {id:null, type: null},
            loading: false,
            error: null,
        },
        
            setHttp( h ) {
                this.http = h;
            },

            setVehicles: function(vehicles) {
                    this.state.vehicles = vehicles
            },
            setSelectedVehicle: function( id ) {
                if (this.state.vehicles.length == 0) {
                    this.fetchVehicles(this.setSelectedVehicle.bind(this, id));
                }
                if (id && this.state.vehicles) {
                    for (const v of this.state.vehicles) {
                        if (v.id == id) {
                            this.state.selectedVehicle = v;
                            return;
                        }
                    }
                } else {
                    this.state.seletedVehicle = {id:null};
                }
            },
            setSelectedEvent: function( id ) {
                if (id && this.state.events) {
                    for (const event of this.state.events) {
                        if (event.id == id) {
                            this.state.selectedEvent = event;
                            return;
                        }
                    }
                } else {
                    this.state.seletedEvent = {id:null, type: null};
                }
            },
            addEvent: function(event) {
                this.state.events.push(event);
            },
            removeEvent: function(event) {
                var index = this.state.events.indexOf(event);
                if (index !== -1) {
                    this.state.events.splice(index, 1);
                }
            },

            fetchVehicles (callback) {
              console.log(process.env);
                this.state.error = this.state.vehicles = null
                this.state.loading = true
                //const fetchedId = this.$route.params.id
                this.http.get(process.env.VUE_APP_API_URL + "vehicle")
                .then((result, err) => {
                  this.state.loading = false
                  if (err) {
                    this.state.error = err.toString()
                  } else {
                    this.state.vehicles = result.data
                    if (callback) { callback() }
                  }
                })
              },

              fetchEvents () {
                this.state.error = null;
                this.state.loading = true
                this.http.get(process.env.VUE_APP_API_URL + "vehicle/" + this.state.selectedVehicle.id + "/event")
                .then((result, err) => {
                  this.state.loading = false
                  if (err) {
                    this.state.error = err.toString()
                  } else {
                    this.state.events = result.data
                  }
                })
              },

              deleteEvent(event) {
                this.state.loading = true
                  this.http.delete(process.env.VUE_APP_API_URL + "event/" + event.id)
                .then((result, err) => {
                  this.state.loading = false
                  if (result.status !== 200) {
                    this.state.error = err.toString()
                  } else {
                    this.removeEvent(event);
                  }
                })
              },
        
}