<template>
<div class="eventList">
  <div class="ui container">
    <div v-if="shared.events" class="content">
      <table class="ui celled table">
        <thead>
          <tr><th class="center aligned">Típus</th>
          <th>Dátum</th>
          <th>Km óra állás</th>
          <th>Mennyiség</th>
          <th>Költség</th>
          <th>Megjegyzések</th>
          <th></th>
        </tr></thead>
        <tbody>
          <tr v-for="event in shared.events" v-bind:key="event.id">
            <td data-label="Típus" class="center aligned"><i :class="[getEventTypeIcon(event.type), ' icon']" :title="getEventTypeName(event.type).toUpperCase()"></i></td>
            <td data-label="Dátum">{{ event.date }}</td>
            <td data-label="Km óra állás">{{ formatNumber(event.km) }}</td>
            <td data-label="Mennyiség">{{ event.type > 1 ? '-' : (event.quantity + ' L') }}</td>
            <td data-label="Költség">{{ formatNumber(event.costs) }} Ft</td>
            <td data-label="Megjegyzések">{{ event.remarks }}</td>
            <td>
              <button class="ui small primary icon button" @click="editEvent(event.id)">
                <i class="pencil icon"></i>
              </button>
              <button class="ui small icon button" @click="deleteEvent(event)">
                <i class="remove icon"></i>
              </button>
              </td>
          </tr>
        </tbody>
        <tfoot class="full-width">
          <tr>
            <th colspan="7">
              <button class="ui left floated small primary labeled icon button" @click="editEvent(null)">
                <i class="add icon"></i> Hozzáadás
              </button>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
    <!--div :class="['ui', this.loading ? 'active' : '', 'dimmer']">
      <div class="ui loader"></div>
    </div-->
  </div>
  <modal v-show="isModalVisible" @close="closeModal"/>
</div>
  
  
</template>

<script>
import store from '../store.js'
import event_mixin from "../mixins/event.js"
import common_mixin from "../mixins/common.js"
import modal from './EventEditorModal.vue';

export default {
  components: {
    modal
  },
  data () {
    return {
      isModalVisible: false,
      shared: store.state
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    '$route': 'fetchData'
  },
  mixins: [ event_mixin, common_mixin ],
  methods: {
    fetchData () {
      store.fetchEvents();
    },
    editEvent (eventId) {
      store.setSelectedEvent(eventId);
      this.showModal();
    },
    deleteEvent(event) {
      store.deleteEvent(event);
    },
    showModal() {
        this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
