<template>
  <div class="home">
    <h1 class="ui header">Járművek listája</h1>
    <div class="ui divider"></div>
    <VehicleList msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import VehicleList from '@/components/VehicleList.vue'

export default {
  name: 'Home',
  components: {
    VehicleList
  }
}
</script>

<style>
  
</style>
